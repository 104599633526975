/**
 * Footer component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa"
import PlayStoreLogo from "../components/Common/PlayStore"

const Footer = () => {

  return (
    <>
      <footer>
          <div className="menu-footer my-4">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 mb-3">
                  <div className="brief-us">
                    <h4>Connect with Us</h4>
                    <p>Stay connected with us by reach us to our social media</p>
                    <ul className="social-media-connect">
                      <li>
                        <a href="http://www.facebook.com/jobsmigascom">
                          <FaFacebook size={20} />
                        </a>
                      </li>
                      <li>
                        <a href=" https://twitter.com/jobsmigasinfo">
                          <FaTwitter size={20} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/jobsmigas/">
                          <FaInstagram size={20} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/jobsmigas-info-76408a125/">
                          <FaLinkedin size={20} />
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                 
                </div>
                <div className="col-12 col-lg-3 mb-3">
                  <h4>Pages</h4>
                  <ul className="page-list">
                    <li>
                      <Link to={'/contact'}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={'/news'}>News</Link>
                    </li>
                    <li>
                      <Link to={'/privacy'}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={'/refund-policy'}>Refund Policy</Link>
                    </li>
                    <li>
                      <Link to={'/terms-conditions'}>Terms and Conditions</Link>
                    </li>
                   
                  </ul>
                </div>

                <div className="col-12 col-lg-3">
                  <div className="download">
                    <h4>Download</h4>
                    <PlayStoreLogo />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-3 py-3">
            © 2011 - {new Date().getFullYear()}. Copyright All Reserved
            {` `}
            <a className="no-link text-danger" href="https://jobsmigas.com"> PT Jobsmigas Karya Indonesia</a>
          </div>
          
        </footer>
    </>
  )
}

export default Footer
