/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa"
import PlayStoreLogo from "../components/Common/PlayStore"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ page, children, withHeader=true, withFooter=true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/*<div  className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                Kini Hadir JOBSMIGAS PREMIUM, Solusi PREMIUM cari lowongan kerja lebih mudah dan lebih dekat dengan HR Perusahaan. <Link to={`/premium`}>Pelajari</Link>
              </p>
            </div>
          </div>
        </div>
        
    </div>*/}
      {withHeader && <Header siteTitle={data.site.siteMetadata.title} />}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1260,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>

      {withFooter && <Footer/>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
