import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RefundPolicyPage = ({uri, location}) => (
    <Layout page="privacy">
        <SEO title="Refund Policy" />
        <div className="container mb-2">
            <div style={{ mposition:"relative", color:"#666", marginBottom: `1.45rem` }}>
                <h2 className="mt-5 mb-5 text-center" style={{color:"#333"}}>Refund Policy</h2>

                <p>
                This page is refund policy for <em>Membership Premium</em> at Jobsmigas.com. 
                If you are not entirely satisfied with your purchase, we&#39;re here to help.
                </p>

                <p> <strong>Refunds</strong> </p>
                <p> After you have declared successful joining the premium membership program, We will not refund your money. </p>

                <p>
                You will get a notification to your email regarding activation of Premium membership.	
                </p>

                <p>
                    <strong>Terms Condition to Refund</strong>
                </p>
                <p>
                If you do not receive an email notification explaining that you have successfully become a premium membership within three days of you making payment, we will definitely refund your payment.
                </p>
                <p>
                If you receive a refund, the costs incurred because of a bank admin will be deducted from your refund.							
                </p>
                <p>
                    <strong>Contact Us</strong>
                </p>
                <p> If you have any questions please contact us <a className="text-muted" href="mailto:support@jobsmigas.co.id">support@jobsmigas.co.id</a>
                </p>
            </div>
        </div>

    </Layout>
)

export default RefundPolicyPage